import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ProductService } from '../../shared/services/product.service';
import {
  navConstrItemsEng, navConstrItemsMar, navMaterilaItemsEng, navMaterilaItemsMar
  , navTransItemsEng, navTransItemsMar
} from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ["./default-layout.component.scss"],

})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navMaterilaItemsEng;
  currentLang = "en";


  selectedProject = 1;
  // 1 : Material
  // 2 : Constucton
  // 3 : Transfor

  public subscriptions$: Subscription[] = [];


  constructor(private router: Router,
    public translate: TranslateService,
    public product: ProductService) { }

  ngOnInit(): void {
    this.subscriptions$.push(
      //------------Subscribe languege change---------------------
      this.product.getcurrentLanguage().subscribe((locale) => {
        this.currentLang = locale;
        localStorage.setItem("locale", locale);
        this.makeMenu();
      })
    );

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);

  }
 
  // opencamponant() {
    
  //   this.router.navigate(['/myprofile']);

  // }
  myFunction() {
    document.getElementById("myDropdown")?.classList.toggle("show");
  }
  public changeLanguage(language: string) {
    localStorage.setItem("locale", language);
    this.currentLang = language;
    this.translate.use(language);
    this.product.setcurrentLanguage(language);
    document.getElementById("myDropdown")?.classList.toggle("show");
  }


  selectProject(project: number) {
    this.selectedProject = project;
    this.makeMenu();
  }


  makeMenu(){
// to do navigate to
    if (this.selectedProject == 1) {
      if (this.currentLang == "mr") {
        this.navItems = navMaterilaItemsMar;
      } else {
        this.navItems = navMaterilaItemsEng;
      }
    }

    if (this.selectedProject == 2) {
      if (this.currentLang == "mr") {
        this.navItems = navConstrItemsMar;
      } else {
        this.navItems = navConstrItemsEng;
      }
    }

    if (this.selectedProject == 3) {
      if (this.currentLang == "mr") {
        this.navItems = navTransItemsMar;
      } else {
        this.navItems = navTransItemsEng;
      }
    }

    this.router.navigateByUrl('dashboard');
  }

}
