// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,                   
  // apiurl: 'https://localhost:44343/api/'
 //apiurl: 'http://agri.knackbe.in/apidata/api/'
  //  apiurl:'https://agriapi.knackbe.in/api/'
  
  // apiurl:'http://qaapi.smartsheti.com/api/'
  // apiurl: 'http://api.smartsheti.com/api/',

  // for dev enviornment   
  // apiurl: 'https://accofinapi.knackbe.in/api/',

  // marathi vachan
   apiurl:'https://mvgameapi.knackbe.in/api/'
  // //  QAmarathi vachan 
   // apiurl:'https://qamvapi.knackbe.in/api/' 

  // production vachan
   
    //apiurl:'https://liveapimarathivachan.knackbe.in/api/'

  // Production hindi vachan url
  // apiurl:'https://livehindivachanapi.knackbe.in/api/'
  // hindi vachan

  //  apiurl:'https://hdvgameapi.knackbe.in/api/'

  // for Qa enviornment
  //  apiurl:'https://qaaccofinapi.knackbe.in/api/',

    // ---------------------------------------------------------------------------- 
    
};
