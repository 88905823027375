import { INavData } from '@coreui/angular';

export const navMaterilaItemsEng: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-list',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name: 'User',
    class: 'module-nav',
    icon: 'icon-user',
    children: [
     
      // {                            
      //   name: 'Students',
      //   url: '/master/users',           
      //   icon: 'icon-cursor'
      // },  
      {
        name: 'Registered-users', 
        url: '/master/register-user',
        icon: 'icon-user', 
      },
      {
        name: 'Employee',
        url: '/master/employee',
        icon: 'icon-user'
      },
      {
        name:'Subscribed-users',
        url: '/master/subscribed-user',
        icon: 'icon-user',
      },
    ]
  },




  {
    name: 'Subscription plans',   
    class: 'module-nav',   
    icon: 'icon-badge', 
    children :[   
      {   
        name: 'Subscription plans', 
        url: '/master/subscription_plans',
        icon: 'icon-fire' 
      }      
    ]

  },

 


  // {
  //   name:'Role',
  //   class:'module-nav',
  //   children: [
  //     // {
  //     //   name: 'Roles',
  //     //   url: '/master/role',
  //     //   icon: 'icon-cursor'

  //     // },
  //     {
  //       name: 'Rights',
  //       url: '/master/rights',
  //       icon: 'icon-cursor'
  //       }
  //   ]
  // },

  
  {
    name: 'Master',
    class: 'module-nav',
    icon: 'icon-list',
    children: [
     
      // {
      //   name: 'Category',
      //   url: '/master/category',
      //   icon: 'icon-cursor',
      // },
      {
        name: 'Sub-category',
        url: '/master/sub_category', 
        icon: 'icon-cursor' 
      },
      {
        name: 'Chapter',
        url: '/master/chapter',
        icon: 'icon-cursor'
      },
      {
         name: 'Chapter sequence',
         url: '/master/Chapter-Sequence',
         icon: 'icon-cursor',
       },
       {
        name: 'SupCate Sequence',
        url: '/master/subcategory-sequence',
        icon: 'icon-cursor',
      },
       {
        name: 'Business value factor',    
        url: '/master/ValueFactor',
        icon: 'icon-cursor',
      },
      
      {
        name: 'Word',
        class: 'module-nav',
        icon: 'icon-puzzle',
        children: [
          // {
          //   name: 'Add Word',
          //   url: '/master/word',
          //   icon: 'icon-cursor',
          // },
          // {
          //   name: 'Word Assessment',
          //   url: '/master/quiz',
          //   icon: 'icon-cursor',  
          // },
          // {
          //   name: 'Word Sequence', 
          //   url: '/master/sequence', 
          //   icon: 'icon-cursor', 
          // },
          {
            name: 'Word allinone',
            url: '/master/allinone',
            icon: 'icon-cursor',
          },
          
        ]
      },

      {
        name: 'Sentence',
        class: 'module-nav',
        icon: 'icon-puzzle',
        children: [
          // {
          //   name: 'Sentence Assessment',
          //   url: '/master/sentence_assessment',
          //   icon: 'icon-cursor',
          // },
          // {
          //   name: 'Sentence Sequence',
          //   url: '/master/sent_sentence',
          //   icon: 'icon-cursor',
          // },

          {
            name: 'Sentence allinone',
            url: '/master/sentenceallinone',
            icon: 'icon-cursor',
          },
          
          // {
          //   name: 'Word Sequence',
          //   url: '/master/sequence',
          //   icon: 'icon-cursor',
          // },
             
        ]
      },
      
      // {
      //   name: 'Placement ',
      //   icon: 'icon-cursor',
      //   class: 'module-nav',
      //   children:[
      //     {
      //       name: 'Job',
      //       url: '/master/placement',
      //       icon: 'icon-',
      //     },
      //     {
      //       name: 'Internship',
      //       url: '/master/internship',
      //       icon: 'icon-',
      //     },

      //   ],
      //  },
      //  {
      //     name: 'Question Set',
      //     url: '/master/queationset',
      //     icon: 'icon-cursor',
      //  },
      // {
      //   name: 'Help and Support',
      //   url: '/master/helpsupport',
      //   icon: 'icon-cursor'
      // },

      // {
      //   name: 'Banner',
      //   url: '/master/banner',
      //   icon: 'icon-cursor'
      // },
      // {
      //   name: 'Blog',
      //   url: '/master/social',
      //   icon: 'icon-cursor'
      // },
      // {
      //   name: 'Company',
      //   url: '/master/company',
      //   icon: 'icon-cursor',
      // },
     
      // // {
      //   name: 'Blog',
      //   url: '/master/product',
      //   icon: 'icon-cursor',
      // },
      // {
      //   name: 'Video',
      //   url: '/master/s',
      //   icon: 'icon-cursor',
      // },
      
    
     
      // {
      //   name: 'Order status',
      //   url: '/dashboard',

      //   // url: '/master/order_status',
      //   icon: 'icon-cursor',
      // },
      // {
      //   name: 'Delivery',
      //   url: '/master/delivery',
      //   icon: 'icon-cursor',
      // },

    ]
  },
  {
    name: ' Report',
    class: 'module-nav',
    icon: 'icon-envelope-letter',
    children :[
      {
        name: ' Report',
        url: '/master/report',
        icon: 'icon-cursor'
      },
      
    ]

  },

 

];

export const navMaterilaItemsMar: INavData[] = [
  {
    name: 'डॅशबोर्ड',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'नवीन'
    }
  },

  {
    name: 'कोर्स',
    icon: 'icon-puzzle',
    class: 'module-nav',
    //   children: [
    //     {
    //       name: 'ग्राहक',
    //       url: '/material/customer',
    //       icon: 'icon-dot'
    //     },
    //     {
    //       name: 'मटेरियल',
    //       url: '/material/materials',
    //       icon: 'icon-dot'
    //     },
    //     {
    //       name: 'कंपनी साठा',
    //       url: '/material/companystock',
    //       icon: 'icon-dot'
    //     },
    //   ]


  },

  {
    name: 'उत्पादन',
    url: '/product/product',
    icon: 'icon-puzzle',

  },
  {
    name: 'गप्पा मारणे',
    url: '/chatting/chatting',
    icon: 'icon-puzzle',

  },
  {
    name: 'प्रोफाइल',
    url: '/profile/profile',
    icon: 'icon-puzzle',

  },
];


export const navConstrItemsEng: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Construction',
    icon: 'icon-layers',
    class: 'module-nav',
    children: [
      {
        name: 'Customers',
        url: '/construction/customers',
        icon: 'icon-dot'
      },
      {
        name: 'Worker',
        url: '/construction/worker',
        icon: 'icon-dot'
      },
      {
        name: 'Site',
        url: '/construction/site',
        icon: 'icon-dot'
      },
      {
        name: 'SiteWork',
        url: '/construction/siteworker',
        icon: 'icon-dot'
      },
      {
        name: 'CustomerPayment',
        url: '/construction/customerpayment',
        icon: 'icon-dot'
      },

      {
        name: 'WorkerPayment',
        url: '/construction/workerpayment',
        icon: 'icon-dot'
      },
    ]
  },
  {
    name: 'DailyExpense',
    url: '/dailyexpense/dailyexpense',
    icon: 'icon-envelope-letter',
  },
];

export const navConstrItemsMar: INavData[] = [
  {
    name: 'डॅशबोर्ड',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'नवीन'
    }
  },
  {
    name: 'बांदकाम',
    icon: 'icon-puzzle',
    class: 'module-nav',
    children: [
      {
        name: 'ग्राहक',
        url: '/construction/customers',
        icon: 'icon-dot'
      },
      {
        name: 'कामगार',
        url: '/construction/worker',
        icon: 'icon-dot'
      },
      {
        name: 'साइट',
        url: '/construction/site',
        icon: 'icon-dot'
      },
      {
        name: 'साइट काम',
        url: '/construction/siteworker',
        icon: 'icon-dot'
      },
      {
        name: 'ग्राहकांचे पेमेंट',
        url: '/construction/customerpayment',
        icon: 'icon-dot'
      },

      {
        name: 'कामगार पेमेंट',
        url: '/construction/workerpayment',
        icon: 'icon-dot'
      },
    ]
  },
  {
    name: 'दैनिक खर्च',
    url: '/dailyexpense/dailyexpense',
    icon: 'icon-puzzle',

  },
];
export const navTransItemsEng: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Transport',
    icon: 'icon-puzzle',
    class: 'module-nav',
    children: [
      {
        name: 'Vehicle',
        url: '/transport/vehicle',
        icon: 'icon-dot'
      },
      {
        name: 'VehiclePayment',
        url: '/transport/vehiclepayment',
        icon: 'icon-dot'
      },
      {
        name: 'CompanyPayment',
        url: '/transport/companypayment',
        icon: 'icon-dot'
      }
    ]
  },

  {
    name: 'DailyExpense',
    url: '/dailyexpense/dailyexpense',
    icon: 'icon-envelope-letter',
  },
];

export const navTransItemsMar: INavData[] = [
  {
    name: 'डॅशबोर्ड',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'नवीन'
    }
  },
  {
    name: 'वाहतूक',
    icon: 'icon-puzzle',
    class: 'module-nav',
    children: [
      {
        name: 'वाहन',
        url: '/transport/vehicle',
        icon: 'icon-dot'
      },
      {
        name: 'वाहन पेमेंट',
        url: '/transport/vehiclepayment',
        icon: 'icon-dot'
      },
      {
        name: 'कंपनी पेमेंट',
        url: '/transport/companypayment',
        icon: 'icon-dot'
      }
    ]
  },
  {
    name: 'दैनिक खर्च',
    url: '/dailyexpense/dailyexpense',
    icon: 'icon-puzzle',

  },
];
